import { styled, theme } from 'twin.macro';

export const RowStyled = styled.div`
  .relateContent {
    color: ${({ theme }) => theme.landingPage.relatedName};
  }
  &.v-season {
    width: 100%;
    margin-top: 10px;
  }
  .v-season {
    &__label {
      display: flex;
      justify-content: flex-end;
    }
    &--dim {
      width: 100%;
      height: 1px;
      background: #1e232a;
    }
    &--select {
      margin: 0;
      span {
        display: none;
        color: #888888;
        margin: 0 calc(1.4rem * var(--multiplierFontSize));
      }
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: column-reverse;
      &-youtube {
        width: 100%;
      }
      &-season {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        &-box {
          width: 100%;
        }
      }
      &-sort {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &-wrap {
          display: flex;
          width: 100%;
          position: relative;
          white-space: nowrap;
          cursor: pointer;
          button {
            background: #ededed;
          }
        }
        &-link {
          padding: calc(1.4rem * var(--multiplierFontSize)) 8px;
          color: #a7b9c4;
          font-size: 14px;
          position: relative;
          margin: 0 calc(0.2rem * var(--multiplierFontSize));
          &--selected,
          &:hover {
            color: #fff;
            font-weight: bolder;
            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 3px;
              bottom: 0;
              left: 0;
              background: ${({ theme }) => theme.primaryColor};
            }
          }
        }
      }
    }
    &--error {
      transform: translateX(-50%);
      position: absolute;
      left: 50%;
    }
    &--loadMore {
      display: flex;
      justify-content: center;
      background: ${({ theme }) => theme.homePage.backgroundRibbon};
    }
    &--loading {
      display: flex;
      height: 300px;
      justify-content: center;
      align-items: center;
    }
    &--col {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      /* flex-basis: 50%;
      max-width: 50%; */
      /* padding: 10px 0 15px 0; */
      .ribbonItemContainer.small {
        width: 100%;
        margin-right: 0;
      }
    }
    &--row {
      /* display: flex;
      flex-wrap: wrap; */

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      margin: 0;
      /* margin-right: -15px; */
      &:before,
      &:after {
        display: none;
      }
    }
    &--imgWrapper {
      overflow: hidden;
      position: relative;
      padding-top: calc(9 * 100% / 16);
    }
    &--img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    &--imgCover {
      object-fit: cover;
      transition: all 0.3s ease 0s;
      cursor: pointer;
      border-radius: 5px;
    }
    &--recomend {
      &-name {
        width: 100%;
        z-index: 0;
        display: flex;
        align-items: center;
        position: relative;
        color: #9b9b9b;
        font-size: 16px;
        padding-top: 10px;
      }
    }
    &__active {
      padding-left: calc(3.2rem * var(--multiplierFontSize));
    }
  }

  @media (min-width: 576px) {
    .v-season {
      &--select {
        flex-direction: row;
      }
      &-season {
        width: 60%;
      }
    }
  }

  @media (min-width: 768px) {
    .v-season {
      /* &--col {
        flex-basis: 33.33%;
        max-width: 33.33%;
      } */
      &--row {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        padding: 20px 0px;
      }

      &--title {
        font-size: 24px;
      }
      &--recomend {
        &-name {
          padding-top: 10px;
          padding-left: 0;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .v-season {
      &--select {
        span {
          display: block;
          white-space: nowrap;
        }
        width: 100%;
        &-season {
          display: flex;
          &-box {
            max-width: 500px;
            width: 100%;
          }
        }
        &-sort {
          &-wrap {
            width: 100%;
          }
        }
      }
      &--row {
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
      }
      &--col {
        /* flex-basis: 25%;
        max-width: 25%; */
        &.small {
          /* flex-basis: 25%; */
        }
      }

      &--title {
        font-size: 26px;
      }
    }
  }
  @media (min-width: 1024px) {
    .v-season {
      &--row {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
  @media (min-width: 1440px) {
    .v-season {
      &--row {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
`;

export const DivStyledLanding = styled.div`
  &.v-landing {
    display: flex;
    flex-direction: column;
    /* background-color: ${({ theme }) => theme.landingPage.background}; */
    .fa-step-backward {
      cursor: pointer;
      padding-left: calc(1.6rem * var(--multiplierFontSize));
      color: #fff;
    }
    .texttruncate {
      color: #d8d8d8;
    }
  }
  .v-landing {
    &__hr {
      width: 100%;
      height: 1px;
      background: #1e232a;
      margin-bottom: 10px;
    }
    &__topAds {
      margin: 50px 0 20px 0;
    }

    &__bottomAds {
      margin: 0px 0 36px 0;
    }

    &__trailer {
      padding: 0;
    }
    &--block-content {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      p {
        font-size: calc(2.4rem * var(--multiplierFontSize));
      }
    }
    &--container {
      width: 100%;
      background: none;
      .v-ytbPage-container,
      .v-ribbon--vertical {
        margin-top: 0;
      }
    }
    &--playButton {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      font-size: calc(1.8rem * var(--multiplierFontSize));
      color: #fff;
      & .playButton {
        background-color: ${({ theme }) => theme.primaryColor};
      }
    }
    &--icon {
      &-ytb {
        width: auto;
        max-width: 70px;
        height: auto;
        margin-left: calc(1.6rem * var(--multiplierFontSize));
      }
    }
    &__banner {
      z-index: 2;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      &-description {
        width: 100%;
      }
      &--wrapImg {
        position: relative;
        width: 100%;
        &:before {
          content: '';
          z-index: 1;
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: 0;
          left: 0;
          background-image: linear-gradient(
            180deg,
            rgba(17, 18, 18, 0) 0%,
            rgba(17, 18, 18, 1) 100%
          );
        }
      }
      &--ytb {
        .v-landing__banner--wrapImg {
          position: relative;
        }
        .v-landing__wrapInfo {
          margin-top: calc(1.25rem * var(--multiplierFontSize));
        }
      }

      &--left {
        background-image: linear-gradient(180deg, rgba(17, 18, 18, 0) 0%, rgba(17, 18, 18, 1) 60%);
      }
      &--thumbnail {
        width: 100%;
        height: auto;
        object-fit: contain;
        position: relative;
        object-position: top;
        background-size: contain;
      }
    }
    &--ribbon {
      width: 100%;
      position: relative;
      z-index: 2;
    }
    &__thumbnail {
      cursor: pointer;
      position: relative;
      padding-top: calc(9 / 16 * 100%);
      background-image: linear-gradient(#fff, #000);
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        background-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 10%,
          rgba(17, 18, 18, 1) 100%
        );
      }
    }
    &--thumbnail {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      object-position: top;
      width: 100%;
      height: 100%;
    }
    &--title {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 100;
      color: #a7b9c4;
      margin-right: 16px;
    }
    &--movieDes {
      margin: 14px 0 16px 0;
      max-height: calc(calc(1.6rem * var(--multiplierFontSize)) * 10 * 1.5);
      font-weight: 100;
      color: ${({ theme }) => theme.landingPage.description};
    }
    &--premium {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #e8b600;
      font-size: calc(1.6rem * var(--multiplierFontSize));
      margin: 0 0 0 calc(1.6rem * var(--multiplierFontSize));
      &--fa {
        padding: 2px;
        font-size: 10px;
        color: #fff;
        background: #e8b600;
        border-radius: 50%;
        margin-right: calc(0.35rem * var(--multiplierFontSize));
        width: 15px;
        height: 15px;
        text-align: center;
      }
      &-txt {
        font-weight: 600;
      }
    }
    &--addList {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      font-size: calc(1.6rem * var(--multiplierFontSize));
      height: 36px;
      border-radius: 50%;
      cursor: pointer;
      background: none;
      /* margin-left: calc(1.6rem * var(--multiplierFontSize)); */
      border: 1px solid #fff;
      &-check {
        font-size: 18px;
      }
      :hover,
      :active,
      :focus {
        background: #fff;
        border: 1px solid ${({ theme }) => theme.landingPage.hoverButton};
        color: #000000;
      }
      :focus {
        outline: none;
        box-shadow: none;
      }
      &.liked {
        border: 1px solid ${({ theme }) => theme.primaryColor};
        i {
          color: ${({ theme }) => theme.primaryColor};
        }
      }
    }
    &--name {
      font-size: 40px;
      text-decoration: none solid rgb(247, 244, 243);
      text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      color: ${({ theme }) => theme.landingPage.bannerColorTitle};
      order: -2;
      margin-bottom: calc(1.4rem * var(--multiplierFontSize));
      margin-top: 0;
    }
    &--btn {
      display: flex;
      align-items: center;
      font-weight: 500;
      background-color: #2574d4;
      color: #fff;
      font-size: 14px;
      text-transform: capitalize;
      padding: 8px 15px 8px 11px;
      margin: 0;
      border-radius: 8px;
      border: none !important;
      outline: none !important;
      max-width: 60%;
      &-upgrade {
        max-width: 100% !important;
      }
      i {
        padding-right: calc(1.4rem * var(--multiplierFontSize));
      }
    }
    &--txtpeopel {
      margin: 0 0 7px 0;
      font-size: 14px;
      color: ${({ theme }) => theme.landingPage.description};
      span,
      a {
        color: ${({ theme }) => theme.primaryColor};
        &:hover {
          text-decoration: none;
        }
      }
      & .highlight {
        color: ${({ theme }) => theme.primaryColor};
      }
      &-directors,
      &-actor {
        color: #fff !important;
        text-transform: uppercase;
      }
    }
    &--numEps {
      margin: 0 0 calc(1.25rem * var(--multiplierFontSize));
    }
    &--category {
      order: -1;
    }
    &--containerDes {
      padding: 0px 15px 16px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      color: ${({ theme }) => theme.landingPage.bannerColorDecription};
      background: ${({ theme }) => theme.landingPage.bannerBackgroundDecription};
      z-index: 4;
    }
    &--iconPlay {
      font-size: calc(0.2rem * var(--multiplierFontSize));
    }

    &__wrapInfo {
      margin: 0 0;
    }
    &--progress {
      margin-bottom: calc(0.4rem * var(--multiplierFontSize));
      height: 7px;
      background: none;
    }
    &__verticalRibbon {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      margin: calc(3.2rem * var(--multiplierFontSize)) 0 0 0;
      .ribbonContainer {
        margin-bottom: 42px;
      }
      .v-ribbon--top-content-title {
        color: ${({ theme }) => theme.ribbon.titleColor};
        font-size: 20px;
        margin-top: 10px;
        font-weight: bold;
      }
    }
    &--bottom {
      &-row {
        width: 100%;
        position: relative;
        z-index: 2;
        padding: 0 15px;
        /* background: ${({ theme }) => theme.homePage.backgroundRibbon}; */
      }
    }
  }

  .fullscreen > .v-landing {
    &__thumbnail {
      height: calc(100vh - 150px);
    }
  }

  @media (min-width: 576px) {
  }

  @media (min-width: 768px) {
    .v-landing {
      &--title {
        margin-bottom: 0px;
      }
      &--containerDes {
        width: 100%;
        position: relative;
      }
      &--iconPlay {
        font-size: calc(1.8rem * var(--multiplierFontSize));
      }
    }
  }

  @media (min-width: 992px) {
    &.v-landing {
      flex-direction: row;
      .fa-step-backward {
        color: #fff;
      }
    }
    .v-landing {
      &--container {
        padding-top: 0px;
        padding-right: calc(1.6rem * var(--multiplierFontSize));
        background: none;
        width: calc(100% - calc(23.2rem * var(--multiplierFontSize)));
      }
      &__verticalRibbon {
        display: block;
        max-width: calc(21.6rem * var(--multiplierFontSize));
        width: 100%;
        padding: 0;
        margin: 0;
        .v-ribbon--vertical {
          margin: 0;
          .v-ads-banner {
            margin-top: 0;
          }
        }
      }
      &__thumbnail {
        width: 100%;
        padding-top: calc(67 / 190 * 100%);
        &:before {
          background-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 50%,
            rgba(17, 18, 18, 1) 100%
          );
        }
      }
      &--category {
        order: initial;
        color: ${({ theme }) => theme.landingPage.description};
      }
      &--name {
        margin-bottom: 19px;
      }
      &--addList {
        /* margin-left: calc(1.6rem * var(--multiplierFontSize)); */
      }
      &--containerDes {
        padding-left: 15px;
        width: 100%;
        /* position: absolute; */
        justify-content: flex-end;
        height: 100%;
        background: none;
        color: rgb(125, 125, 125);
        padding-bottom: calc(1.25rem * var(--multiplierFontSize));
      }
      &--numEps {
        margin: 0 0 15px 0;
      }
      &--movieDes {
        margin: 16px 0;
        color: ${({ theme }) => theme.landingPage.description};
        text-shadow: 0px 1px 1px #000000;
      }
      &--iconPlay {
        font-size: calc(2.4rem * var(--multiplierFontSize));
      }
      &__banner {
        flex-direction: row;
        position: relative;
        &--ytb {
          .v-landing__wrapInfo {
            margin: calc(1.25rem * var(--multiplierFontSize)) 0 0;
          }
          .v-landing__banner--left {
            background-image: unset;
          }
          .v-landing__banner--wrapImg {
            position: absolute;
            width: 100%;
          }
          .v-landing--containerDes {
            position: relative;
          }
        }
        &--thumbnail {
          width: 100%;
          height: auto;
          object-fit: contain;
          position: relative;
          object-position: top;
          background-size: contain;
        }
        &--gradient {
          width: 100%;
          position: absolute;
          background: linear-gradient(270deg, rgba(17, 18, 18, 0) 50%, rgba(17, 18, 18, 0.9) 100%),
            linear-gradient(90deg, rgba(17, 18, 18, 0) 50%, rgba(17, 18, 18, 0.9) 100%);
          height: 100%;
        }
        &__wrapInfo {
          margin: 0;
        }
        &--left {
          left: 0;
          width: 100%;
          justify-content: flex-start;
          padding: calc(1.6rem * var(--multiplierFontSize)) 15px 0 15px;
          background-image: linear-gradient(
            180deg,
            rgba(17, 18, 18, 0) 0%,
            rgba(255, 255, 255, 0) 00%,
            rgba(17, 18, 18, 1) 60%
          );
        }
        &--right {
          padding: 0;
          width: 52%;
          position: absolute;
          right: 8%;
          justify-content: center;
          &-description {
            padding: calc(3.2rem * var(--multiplierFontSize));
            border-radius: 10px;
            background: #ffffff;
            box-shadow: 0px 1px 20px rgb(0 0 0 / 40%);
            .v-landing--txtpeopel {
              color: #7d7d7d;
              span {
                color: ${({ theme }) => theme.primaryColor};
              }
            }
            .v-landing--movieDes {
              color: #7d7d7d;
              text-shadow: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .v-landing {
      &--container {
        padding-top: 0px;
      }
      /* &--name {
        -webkit-line-clamp: 2;
        max-height: calc(calc(2.4rem * var(--multiplierFontSize)) * 2 * 1.5);
        line-height: calc(4rem * var(--multiplierFontSize));
      } */
    }
  }
  @media (orientation: landscape) and (max-width: 1024px) {
    .v-landing {
      &--btn {
        max-width: 70%;
      }
    }
  }

  @media (min-width: 1440px) {
  }

  @media (min-width: 1891px) {
    .v-landing {
      &__banner {
        &--ytb {
          .v-landing__banner--wrapImg {
            position: relative;
          }
          .v-landing--containerDes {
            position: absolute;
          }
        }
        &__leftAd,
        &__rightAd {
          width: 160px;
          margin: 0 15px;
          height: 600px;
          color: #8c8c8c;
          background-color: #dadada;
        }
      }
    }
  }
`;
